<template>
  <div class="container">
    <!-- logo -->
    <div class="logo">
      <img src="../../assets/images/applyWaitingSing.gif" alt="" />
    </div>
    <!-- 描述 -->
    <div class="descript">
      <h5>签署中，请稍候...</h5>
      <p>将为您创建CA证书和签署电子合同 <br />正在创建..</p>
    </div>
    <div class="jdt">
      <img src="../../assets/images/applyWaitingLB.gif" alt="" class="lb" />
    </div>
  </div>
</template>

<script>
export default {
  name: "applyWaiting",
  data() {
    return {
      form: {
        channelNo: this.$route.query.source, // 渠道标识
        loanAmount: this.$route.query.amount ? this.$route.query.amount : 0, // 借款金额
      }, // 表单信息
      applyId: this.$route.query.applyId, // 申请编号
      brand: this.$route.query.brand, // 设备型号
      downPageUrl: this.$route.query.downPageUrl, // 进件结果下载页地址
      isRedirectProductsPage: parseInt(
        this.$route.query.isRedirectProductsPage
      ), // 标识是否是贷超流程的字段1是 0否
      isRedirectWorkWeixinPage: parseInt(
        this.$route.query.isRedirectWorkWeixinPage
      ), // 标识如果不是贷超流程需要用此字段进行判断是否跳转引导企业微信页面中，引导用户用企业微信扫码联系客服1是 0否
    };
  },
  created() {
    // 跳转到结果页
    setTimeout(() => {
      this.$router.push({
        name: "TrialSuccessTl8",
        query: {
          source: this.form.channelNo,
          amount: this.form.loanAmount,
          applyId: this.applyId,
          isRedirectProductsPage: this.isRedirectProductsPage,
          isRedirectWorkWeixinPage: this.isRedirectWorkWeixinPage,
        },
      });
    }, 1500);
  },
};
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  .logo {
    margin-top: 2.15rem;
    img {
      width: 2.15rem;
      height: 2.1rem;
    }
  }
  .descript {
    margin-top: 0.19rem;
    h5 {
      font-size: 0.32rem;
      color: #222;
      font-weight: 400;
      font-family: "SourceHanSansCN-Medium";
    }
    p {
      margin-top: 0.15rem;
      font-size: 0.24rem;
      color: #999999;
    }
  }
  .jdt {
    position: relative;
    top: -0.7rem;
    .lb {
      width: 0.66rem;
      height: 0.16rem;
    }
  }
}
</style>
